import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import axios from 'axios';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import LocaleSelect from 'components/global/LocaleSelect';
import { API_URL } from '../../../environment';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
  },
}));

const theme = createTheme();

const SignIn = props => {
  const classes = useStyles();
  const [apiError, setApiError] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const credentials = {
      email: data.get('email'),
      password: data.get('password'),
    };

    try {
      const response = await axios.post(
        `${API_URL}/clinic_admin/sessions`,
        credentials,
      );
      const token = response.data.access_token;
      localStorage.setItem('token', token);
      props.history.push('/dashboard_clinic');
    } catch (error) {
      const errorData = error.response.data;
      let combinedErrorMessage = '';

      if (errorData.errors) {
        combinedErrorMessage = errorData.errors
          .map(err => `${err.source.pointer}: ${err.detail}`)
          .join('\n');
        setApiError(combinedErrorMessage);
      } else {
        let errorMessage = `${props.intl.formatMessage({
          defaultMessage: 'Something went wrong',
        })}: `;
        errorMessage += error?.response?.data?.error || '';
        setApiError(errorMessage);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div style={{ marginBottom: '100px' }}>
          <AppBar position="absolute">
            <Toolbar className={classes.toolbar}>
              <LocaleSelect />
            </Toolbar>
          </AppBar>
        </div>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            <FormattedMessage defaultMessage="Clinic Administrator Sign In" />
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit}
            onChange={() => setApiError('')}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label={<FormattedMessage defaultMessage="Email" />}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="password"
                  label={<FormattedMessage defaultMessage="Password" />}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  inputProps={{ minLength: 8 }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!!apiError}
            >
              <FormattedMessage defaultMessage="Sign In" />
            </Button>
          </form>
          {apiError && <Typography color="error">{apiError}</Typography>}
        </div>
      </Container>
    </ThemeProvider>
  );
};

SignIn.propTypes = {
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(SignIn));
