import React, { useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import LocaleSelect from 'components/global/LocaleSelect';
import MyAccount from './myAccount';
import { API_URL } from '../../../environment';
import { headers } from '../../../actions/defaults';
import { signoutUser } from '../../../actions/auths';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    marginTop: '80px',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const Dashboard = props => {
  const classes = useStyles();
  const [clinicAdminData, setClinicAdminData] = React.useState({});

  useEffect(() => {
    axios
      .get(`${API_URL}/clinic_admin/users/me`, headers())
      .then(response => {
        setClinicAdminData(response.data);
      })
      .catch(() => {
        props.history.push('/signin_clinic');
      });
  }, []);

  const signOutHandler = () => {
    props.signoutUser();
    props.history.push('/signin_clinic');
  };

  return (
    <ThemeProvider>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {clinicAdminData?.clinic?.clinic_name}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => signOutHandler()}
            >
              <Typography
                component="subtitle1"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <FormattedMessage defaultMessage="Logout" />
              </Typography>
            </IconButton>
            <LocaleSelect />
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <MyAccount clinicAdminData={clinicAdminData} />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
};

Dashboard.propTypes = {
  signoutUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(
  injectIntl(connect(null, { signoutUser })(Dashboard)),
);
