import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import axios from 'axios';
import {
  BrowserRouter,
  Switch,
  Redirect as RedirectRouter,
  Route,
} from 'react-router-dom';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import reduxThunk from 'redux-thunk';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui';
import Loadable from 'react-loadable';
import '../style/libs/bootstrap.min.css';
import '../style/style.scss';
import SecureMessaging from 'components/admin/secure_messaging/SecureMessaging';

// clinic
import DashboardClinic from 'components/clinic/dashboard';
import ClinicOnboarding from 'components/clinic/onboarding';
import SignInClinic from 'components/clinic/signin';

// eslint-disable-next-line import/no-cycle
import AppLayout from './layouts/AppLayout';
import LDProvider from './LDProvider';
import LanguageProvider from './LanguageProvider';
import { ENVIRONMENT, API_URL } from './environment';
import { AUTH_USER } from './actions/types';
import MuiTheme from './theme';
import AppRoute from './layouts/AppRoute';
import SessionLayout from './layouts/SessionLayout';
import NewVideoLayout from './layouts/NewVideoLayout';
import ScrollToTop from './utils/ScrollToTop';
import { initAmplitude } from './utils/amplitude';
import { useDefaultTimezone } from './utils/timezone';
import reducers from './reducers';
import ModuleLoadingSpinner from './components/global/ModuleLoadingSpinner';

// Authentication
import Redirect from './components/auth/Redirect';
import RedirectClientVideo from './components/auth/RedirectClientVideo';
import RequireAuth from './components/auth/RequireAuth';
import RequireAdmin from './components/auth/RequireAdmin';
import VerifyPin from './components/auth/TwoFactor/VerifyPin';
import Signin from './components/auth/SignIn';
import ZendeskRedirect from './components/auth/ZendeskRedirect';
import ForgotPassword from './components/auth/ForgotPassword';
import ForgotPasswordConfirmation from './components/auth/ForgotPasswordConfirmation';
import ResetPassword from './components/auth/ResetPassword';
import Signout from './components/auth/SignOut';
import ConfirmationEmail from './components/auth/ConfirmationEmail';
import Confirmation from './components/auth/Confirmation';
// Onboarding
import PracticeOnboarding from './components/onboarding';
import GenericOnboarding from './components/onboarding_generic';

// Sessions
import SessionAuth from './components/sessions';
import WaitingRoom from './components/sessions/waiting_room';
import PostSessionAssessment from './components/sessions/postSessionAssessment/index';
import PractitionerVideo from './components/sessions/practitioner_video';
import Video from './components/sessions/video';
import InPerson from './components/sessions/in_person';
import NotFoundPage from './NotFoundPage';
import SharedFilesRoutes from './components/shared_files/SharedFilesRoutes';

// Amplitude
initAmplitude();

axios.interceptors.request.use(
  request => {
    if (request.url.includes(API_URL)) {
      const locale = localStorage.getItem('locale') || 'en';
      request.params = { ...request.params, locale };
    }
    return request;
  },
  error => Promise.reject(error),
);

// export const store = createStore(reducers, applyMiddleware(reduxThunk));
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      reduxThunk,
      loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
      }),
    ),
  ),
);

// Use previously set timezone
useDefaultTimezone();

const token = localStorage.getItem('token');
if (token !== 'undefined' && token !== undefined && token !== null) {
  store.dispatch({ type: AUTH_USER });
}

if (ENV_SENTRY_DSN) {
  Sentry.init({
    dsn: ENV_SENTRY_DSN,
    environment: ENVIRONMENT,
    release: ENV_APP_VERSION, // eslint-disable-line no-undef
  });
}

export const inkThemeV0 = getMuiTheme(MuiTheme);
export const inkTheme = createTheme(MuiTheme);

const Dashboard = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard" */ 'components/dashboard/Dashboard'
    ),
  loading: ModuleLoadingSpinner,
});

const Schedule = Loadable({
  loader: () =>
    import(/* webpackChunkName: "schedule" */ 'components/schedule'),
  loading: ModuleLoadingSpinner,
});

const SupportRoutes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "support" */ 'components/support/SupportRoutes'
    ),
  loading: ModuleLoadingSpinner,
});

const PreferencesRoutes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "preferences" */ 'components/preferences/PreferencesRoutes'
    ),
  loading: ModuleLoadingSpinner,
});

const Billing = Loadable({
  loader: () => import(/* webpackChunkName: "billing" */ 'components/billing'),
  loading: ModuleLoadingSpinner,
});

const SessionNotesRoutes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "process" */ 'components/session_notes/SessionNotesRoutes'
    ),
  loading: ModuleLoadingSpinner,
});

const ClientsRoutes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "clients" */ 'components/clients/ClientsRoutes'
    ),
  loading: ModuleLoadingSpinner,
});

const MessagingRoutes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard" */ 'components/messaging/MessagingRoutes'
    ),
  loading: ModuleLoadingSpinner,
});

const PracticeManagementRoutes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "clients" */ 'components/practice_management/PracticeManagementRoutes'
    ),
  loading: ModuleLoadingSpinner,
});

const AdminRoutes = Loadable({
  loader: () =>
    import(/* webpackChunkName: "admin" */ 'components/admin/AdminRoutes'),
  loading: ModuleLoadingSpinner,
});

const PayoutsRoutes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "payouts" */ 'components/payouts/PayoutsRoutes'
    ),
  loading: ModuleLoadingSpinner,
});

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider>
      <LDProvider>
        <MuiThemeProvider theme={inkTheme}>
          <V0MuiThemeProvider muiTheme={inkThemeV0}>
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  <AppRoute
                    layout={AppLayout}
                    path="/dashboard"
                    component={RequireAuth(Dashboard)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/schedule"
                    component={RequireAuth(Schedule)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/clients"
                    component={RequireAuth(ClientsRoutes)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/session_notes"
                    component={RequireAuth(SessionNotesRoutes)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/shared_files"
                    component={RequireAuth(SharedFilesRoutes)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/billing"
                    component={RequireAuth(Billing)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/messages"
                    component={RequireAuth(MessagingRoutes)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/preferences"
                    component={RequireAuth(PreferencesRoutes)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/practice_management"
                    component={RequireAuth(PracticeManagementRoutes)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/support"
                    component={RequireAuth(SupportRoutes)}
                  />
                  <AppRoute
                    layout={props => <div>{props.children}</div>}
                    path="/admin/secure_messaging"
                    component={RequireAuth(RequireAdmin(SecureMessaging))}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/admin"
                    component={RequireAuth(RequireAdmin(AdminRoutes))}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/forgot_password"
                    component={ForgotPassword}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/forgot_password_confirmation"
                    component={ForgotPasswordConfirmation}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/redirect/:token"
                    component={Redirect}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/reset_password/:token"
                    component={ResetPassword}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/confirmation/:token"
                    component={Confirmation}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/confirmation"
                    component={ConfirmationEmail}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/signin"
                    component={Signin}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/zendesk_redirect"
                    component={ZendeskRedirect}
                  />

                  <AppRoute
                    layout={AppLayout}
                    path="/two_factor"
                    component={VerifyPin}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/signout"
                    component={Signout}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/signup"
                    component={PracticeOnboarding}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/signup_specialized"
                    component={GenericOnboarding}
                  />
                  <AppRoute
                    layout={SessionLayout}
                    exact
                    path="/sessions/:id/waiting_room/:token?"
                    component={SessionAuth(WaitingRoom)}
                  />
                  <AppRoute
                    layout={SessionLayout}
                    path="/sessions/:id/post_session_assessments"
                    component={RequireAuth(PostSessionAssessment)}
                  />
                  <AppRoute
                    layout={SessionLayout}
                    path="/sessions/:id/in_person"
                    component={RequireAuth(InPerson)}
                  />
                  <AppRoute
                    layout={NewVideoLayout}
                    path="/sessions/clients/video_call/:id"
                    component={RequireAuth(Video)}
                  />
                  <AppRoute
                    layout={props => <div>{props.children}</div>}
                    path="/sessions/clients/redirect/:appointment_id/:token"
                    component={RedirectClientVideo}
                  />

                  <AppRoute
                    layout={SessionLayout}
                    path="/sessions/providers/video_call/:id"
                    component={RequireAuth(PractitionerVideo)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    path="/payouts"
                    component={RequireAuth(PayoutsRoutes)}
                  />
                  <AppRoute
                    layout={AppLayout}
                    exact
                    path="/"
                    component={RequireAuth(Dashboard)}
                  />
                  <AppRoute
                    layout={props => <div>{props.children}</div>}
                    path="/404"
                    component={NotFoundPage}
                  />
                  {/* clinic routes - refactor might needed */}
                  <Route path="/signup_clinic" component={ClinicOnboarding} />
                  <Route path="/dashboard_clinic" component={DashboardClinic} />
                  <Route path="/signin_clinic" component={SignInClinic} />
                  <RedirectRouter to="/404" />
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </V0MuiThemeProvider>
        </MuiThemeProvider>
      </LDProvider>
    </LanguageProvider>
  </Provider>,
  document.querySelector('#root'),
);
